<template>
  <div class="obligation-two">
    <div class="order-list df fdc">
      <div class="item mb20 p15 bsbb" v-for="(v, i) in unfinishedOrder" :key="i">
        <div class="df aic jcsb font-size-24 fw7" style="border-bottom: 1px solid #f2f2f2; padding-bottom: 8px">
          <p>全民百惠</p>
          <p>{{ changeTime(v.create_time * 1000) }} 下单</p>
        </div>
        <div class="df aic jcsb text p15 bsbb">
          <div class="df aic font-size-20">
            <img :src="v.order_img" class="mr20" />
            <p style="color: #77d477">写评价,商品好坏就差你的发声了</p>
          </div>
         <!-- <van-button type="default" @click="goPay(v.id)">去付款</van-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMyOrderReq } from "@/utils/api.js";
export default {
  data() {
    return {
      unfinishedOrder: [],
    };
  },
  methods: {
    changeTime(date) {
      let time = new Date(date);
      let y = time.getFullYear();
      let m = time.getMonth() + 1;
      let d = time.getDate();
      return [y, m, d].map((v) => String(v).padStart(2, "0")).join("-");
    },
    goPay() {
      this.$router.push(`/all/obligation`)
    },
  },
  mounted() {
    getMyOrderReq({ page: 1, page_size: 50 }).then((res) => {
      this.orderList = res.data.list;
      this.unfinishedOrder = this.orderList.filter(
        (v) => v.payment_status == 10
      );
      console.log(this.unfinishedOrder);
    });
  },
};
</script>

<style lang="scss" scoped>
.obligation-two {
  .order-list {
    .item {
      width: 684px;
      height: 213px;
      border-radius: 20px;
      background: rgba(255, 255, 255, 1);

      .text {
        img {
          width: 100px;
          height: 100px;
          border-radius: 8px;
        }

        .van-button {
          width: 129px;
          height: 57px;
          border-radius: 15px;
        }
      }
    }
  }
}
</style>
